<template>

  <div>

    <comissions-list-add-new
      :is-add-new-comission-sidebar-active.sync="isAddNewComissionSidebarActive"
      :comission-id="selectedComissionId"
      @new-comission="setComissions($event)"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="pagination.per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="(value)=>{handlePagination({ per_page: value, page: 1 })}"
            />
            <label>resultados</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isAddNewComissionSidebarActive = true"
              >
                <span class="text-nowrap">Agregar comisión</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="comissions"
        :fields="tableColumns"
        responsive
        primary-key="id"
        show-empty
        empty-text="No existen registros aún"
      >
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.carrier_name)"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.carrier_name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Comisión general -->
        <template #cell(global_commission)="data">
          <p>
            {{ data.value | money }} %
          </p>
        </template>

        <!-- Column: Comisión por tienda -->
        <template #cell(store_commission)="data">
          <p>
            {{ data.value | money }} %
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="isEditComission(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            v-if="pagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ pagination.per_page }} of {{ pagination.total_objects }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="pagination.page"
              :total-rows="pagination.total_objects"
              :per-page="pagination.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable,
  BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { mapActions } from 'vuex'
import ComissionsListAddNew from './ComissionsListAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ComissionsListAddNew,
  },
  data() {
    return {
      avatarText,
      isAddNewComissionSidebarActive: false,
      selectedComissionId: null,
      tableColumns: [
        {
          label: 'Proveedor',
          key: 'name',
          sortable: true,
        },
        {
          label: 'Comisión general',
          key: 'global_commission',
          sortable: true,
        },
        {
          label: 'Comisión por tienda',
          key: 'store_commission',
          sortable: true,
        },
        {
          label: '',
          key: 'actions',
          sortable: true,
        },
      ],
      comissions: [],
      pagination: {},
    }
  },
  computed: {},
  beforeMount() {
    this.fetchEmidaComissions()
      .then(response => {
        this.comissions = response.data
        this.pagination = response.meta.pagination
      })
  },
  methods: {
    ...mapActions('emidaComissions', ['fetchEmidaComissions', 'collectComissionManually']),
    isEditComission(id) {
      this.selectedComissionId = id
      this.isAddNewComissionSidebarActive = true
    },
    setComissions(comissions) {
      this.comissions = comissions.data
      this.pagination = comissions.meta.pagination
    },
    handlePagination({ page, per_page }) {
      this.fetchEmidaComissions({
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
        .then(response => {
          this.comissions = response.data
          this.pagination = response.meta.pagination
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
